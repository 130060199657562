import React from 'react';
import partner1 from '../assert/partner1.png' 
import heyi from '../assert/heyi.png'
import logo from '../assert/logo3.svg'
import wechat from '../assert/wechat.jpg'
import wecom from '../assert/wecom.png'
import waterJoy from '../assert/waterJoy.png'
import waterLXDXY from '../assert/waterLXDXY.png'
import waterLXSY from '../assert/waterLXSY.png'
import waterJoys500 from '../assert/waterJoys500.png'
import rightIcon from '../assert/rightIcon.png'
import electricityDDS5 from '../assert/electricityDDS5.png'
import electricityDDSU2p from '../assert/electricityDDSU2p.png'
import electricityDDSU4p from '../assert/electricityDDSU4p.png'
import electricityDTSD from '../assert/electricityDTSD.png'
import hotJOYR200 from '../assert/hotJOYR200.png'
import hotJOYH110 from '../assert/hotJOYH110.png'
import gatewayCT213 from '../assert/gatewayCT213.png'
import gatewayCV151 from '../assert/gatewayCV151.png'
import gatewayGW220 from '../assert/gatewayGW220.png'
import Pricing1 from '../assert/Pricing1.png'
import Pricing2 from '../assert/Pricing2.png'
import Pricing3 from '../assert/Pricing3.png'
import project1 from '../assert/project1.png'
import project2 from '../assert/project2.png'
import project3 from '../assert/project3.png'
import soft1 from '../assert/soft1.png'
import soft2 from '../assert/soft2.png'
import soft3 from '../assert/soft3.png'
import { Button, Radio, Icon } from 'antd';
import { Link } from 'react-router-dom';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children:logo,
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',//这是目录的key
        className: 'header0-item',
        children: {
          href: '#',
          children: [{ children: '产品', name: 'text' }],
        },
        subItem: [
          {
            name: 'software', //下拉的key
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              to: {pathname:'/software',state:{name:'产品',type:'software'}},
              children: [
                {
                  name: 'title',
                  className: 'item-title', 
                  children: '软件产品',
                },
              
              ],
            },
          },
          {
            name: 'hardware',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              to: {pathname:'/hardware',state:{name:'产品',type:'hardware'}},
              children: [
               
                {
                  name: 'title',
                  className: 'item-title',
                  children: '硬件产品',
                },
               
              ],
            },
          },
        ],
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#',
          children: [{ children: '解决方案', name: 'text' }],
        },
        subItem: [
          {
            name: 'flats',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              to: {pathname:'/solution',state:{name:'长租公寓',type:'flats'}},
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: '长租公寓',
                },
              
              ],
            },
          },
          {
            name: 'publicHousing',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              to: {pathname:'/solution',state:{name:'公租房',type:'publicHousing'}},
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: '公租房',
                },
               
              ],
            },
          },
          {
            name: 'market',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              to: {pathname:'/solution',state:{name:'智慧商场',type:'market'}},
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: '智慧商场',
                },
               
              ],
            },
          },
          {
            name: 'officeBuilding',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              to: {pathname:'/solution',state:{name:'写字楼',type:'officeBuilding'}},
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: '写字楼',
                },
               
              ],
            },
          },
          {
            name: 'park',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              to: {pathname:'/solution',state:{name:'园区能耗',type:'park'}},
              children: [
              
                {
                  name: 'title',
                  className: 'item-title',
                  children: '园区能耗',
                },
               
              ],
            },
          },
        ],
      },
      // {
      //   name: 'item2',
      //   className: 'header0-item',
      //   children: {
      //     children: [{ children: 
      //       <Link to={'/tool'} style={{color:'white'}}>行业工具</Link>
      //       , name: 'text' ,id:'only'}],
      //   },
      // },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          children: [{ children: 
            <Link to={'/about'} style={{color:'white'}}>关于我们</Link>
            , name: 'text' ,id:'only'}],
        },
      },
      {
        name: 'item4',
        className: 'header0-item',
        children: {
          href: '#',
          children: [{ children: (
            <div style={{marginLeft:'-16px'}}>
            <Button icon="phone" size='default' ghost onClick={()=>{
              window.location.href='tel:400-826-6829'
            }}>
            400-826-6829
          </Button>
          </div>
          ), name: 'text' ,}],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page ledyhtbjaai-editor_css' },
        textWrapper: {
          className: 'banner2-text-wrapper ledwgwyu4g-editor_css',
        },
        bg: { className: 'bg bg0 ledyhz80lwh-editor_css' },
        title: {
          className: 'banner2-title',
          children: (
            <span>
              <p>能源管理新价值</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content ledwft0rblo-editor_css',
          children: (
            <span style={{display:'block', wordWrap:'break-word'}}>
              <p>
              为能源计量赋予IOT连接和数据服务能力。
              </p>
            </span>
          ),
        },
        button: {
          className: 'banner2-button ledwf9pdefj-editor_css',
          children: 'Learn More',
        },
      },
    ],
  },
};
export const BannerHardwareDataSource = {
  wrapper: { className: 'banner2 banner3' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page ledyhtbjaai-editor_css' },
        textWrapper: {
          className: 'banner2-text-wrapper ledwgwyu4g-editor_css',
        },
        bg: { className: 'bg bg1 ledyhz80lwh-editor_css' },
        title: {
          className: 'banner2-title',
          children: (
            <span>
              <p>硬件产品</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content ledwft0rblo-editor_css',
          children: (
            <span style={{display:'block',marginTop:'1rem', wordWrap:'break-word',letterSpacing:'4px',fontSize:'24px'}}>
              <p>
                HARDWARE PRODUCTS
              </p>
            </span>
          ),
        },
        button: {
          className: 'banner2-button ledwf9pdefj-editor_css',
          children: 'Learn More',
        },
        breadcrumb:{
          className: 'breadcrumb',
          children:'lookthis'
        }
      },
    ],
  },
};
export const BannerSoftwareDataSource = {
  wrapper: { className: 'banner2 banner3' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page ledyhtbjaai-editor_css' },
        textWrapper: {
          className: 'banner2-text-wrapper ledwgwyu4g-editor_css',
        },
        bg: { className: 'bg bg2 ledyhz80lwh-editor_css' },
        title: {
          className: 'banner2-title',
          children: (
            <span>
              <p>软件产品</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content ledwft0rblo-editor_css',
          children: (
            <span style={{display:'block',marginTop:'1rem', wordWrap:'break-word',letterSpacing:'4px',fontSize:'24px'}}>
              <p>
                SOFTWARE PRODUCTS
              </p>
            </span>
          ),
        },
        button: {
          className: 'banner2-button ledwf9pdefj-editor_css',
          children: 'Learn More',
        },
        breadcrumb:{
          className: 'breadcrumb',
          children:'lookthis'
        }
      },
    ],
  },
};
export const BannerLXSYDataSource = {
  wrapper: { className: 'banner2 banner3' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page ledyhtbjaai-editor_css' },
        textWrapper: {
          className: 'banner2-text-wrapper ledwgwyu4g-editor_css',
        },
        bg: { className: 'bg bg1 ledyhz80lwh-editor_css' },
        title: {
          className: 'banner2-title',
          children: (
            <span>
              <p>硬件产品</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content ledwft0rblo-editor_css',
          children: (
            <span style={{display:'block',marginTop:'1rem', wordWrap:'break-word',letterSpacing:'4px',fontSize:'24px'}}>
              <p>
                HARDWARE PRODUCTS
              </p>
            </span>
          ),
        },
        button: {
          className: 'banner2-button ledwf9pdefj-editor_css',
          children: 'Learn More',
        },
        breadcrumb:{
          className: 'breadcrumb',
          children:'lookthis'
        }
      },
    ],
  },
};
export const BannerSoluntionDataSource = {
  wrapper: { className: 'banner2 banner3' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page ledyhtbjaai-editor_css' },
        textWrapper: {
          className: 'banner2-text-wrapper ledwgwyu4g-editor_css',
        },
        bg: { className: 'bg bg3 ledyhz80lwh-editor_css' },
        title: {
          className: 'banner2-title',
          children: (
            <span>
              <p>解决方案</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content ledwft0rblo-editor_css',
          children: (
            <span style={{display:'block',marginTop:'1rem', wordWrap:'break-word',letterSpacing:'4px',fontSize:'24px'}}>
              <p>
              SOLUTION
              </p>
            </span>
          ),
        },
        button: {
          className: 'banner2-button ledwf9pdefj-editor_css',
          children: 'Learn More',
        },
        breadcrumb:{
          className: 'breadcrumb',
          children:'lookthis'
        }
      },
    ],
  },
};
export const BannerAboutDataSource = {
  wrapper: { className: 'banner2 banner3' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page ledyhtbjaai-editor_css' },
        textWrapper: {
          className: 'banner2-text-wrapper ledwgwyu4g-editor_css',
        },
        bg: { className: 'bg bg4 ledyhz80lwh-editor_css' },
        title: {
          className: 'banner2-title',
          children: (
            <span>
              <p>关于我们</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content ledwft0rblo-editor_css',
          children: (
            <span style={{display:'block',marginTop:'1rem', wordWrap:'break-word',letterSpacing:'4px',fontSize:'24px'}}>
              <p>
              ABOUT US
              </p>
            </span>
          ),
        },
        button: {
          className: 'banner2-button ledwf9pdefj-editor_css',
          children: 'Learn More',
        },
        breadcrumb:{
          className: 'breadcrumb',
          children:'lookthis'
        }
      },
    ],
  },
};

export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>企业数字化能源管理</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <p>DIGITAL ENERGY MANAGEMENT FOR RNTERPRISES</p>
              </span>
            </span>
          </span>
        ),
        className: 'ledx5lhxzim-editor_css',
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { children: '源一能耗平台', className: 'content7-tag-name' },
          icon: { children: null },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <div>
                <div style={
                  {fontSize:'1.5rem'}
                }>Metering Expert</div>
                <div style={
                  {fontSize:'2rem',fontWeight:'bolder'}
                }>计量行业专家</div>
                <p style={{marginTop:'1rem',width:'23rem',letterSpacing:'0.2rem',lineHeight:'2rem'}} >
                合一智能抄表致力于为客户提供专业的表计数据服务，是源一物联深根能源计量领域多年打造的表计IOT平台。
                </p>
              </div>
            ),
          },
          img: {
            className: 'content7-img',
            children:heyi,
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: null},
          text: { className: 'content7-tag-name', children: '合一智能抄表' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <div>
                <div style={
                  {fontSize:'1.5rem'}
                }>Metering Expert</div>
                <div style={
                  {fontSize:'2rem',fontWeight:'bolder'}
                }>计量行业专家</div>
                <p style={{marginTop:'1rem',width:'23rem',letterSpacing:'0.2rem',lineHeight:'2rem'}} >
                合一智能抄表致力于为客户提供专业的表计数据服务，是源一物联深根能源计量领域多年打造的表计IOT平台。
                </p>
              </div>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:heyi,
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: { children: 'AMS租赁管理系统', className: 'content7-tag-name' },
          icon: { children: null },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <div>
                <div style={
                  {fontSize:'1.5rem'}
                }>Metering Expert</div>
                <div style={
                  {fontSize:'2rem',fontWeight:'bolder'}
                }>计量行业专家</div>
                <p style={{marginTop:'1rem',width:'23rem',letterSpacing:'0.2rem',lineHeight:'2rem'}} >
                合一智能抄表致力于为客户提供专业的表计数据服务，是源一物联深根能源计量领域多年打造的表计IOT平台。
                </p>
              </div>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:heyi,
          },
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'mec',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={waterLXSY} alt=''/>
                        },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            { name: 'content', 
            className: 'content0-block-detail',
            children: '支卡扣式无线远传水表' },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>LXSY系列</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
      {
        name: 'block3',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'s500',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={waterJoys500} alt=''/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'content0-block-detail',
              children: 'JOYS500光电直读水表',
            },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>JOYS500</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'s663',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={waterJoy} alt=''/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'content0-block-detail',
              children: 'JOYS663超声波水表',
            },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>JOYS663</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'voha',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={waterLXDXY} alt=''/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'content0-block-detail',
              children: 'Voha物联网电子远传水表',
            },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>LXDXY系列</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
      
    ],
  },
};
export const Feature01DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'ddsu2p',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={electricityDDSU2p} alt=''/>
                        },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            { name: 'content', 
            className: 'content0-block-detail',
            children: '单相电子式电能表（导轨2P）' },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>DDSU5168</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'dds5168b',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={electricityDDS5} alt=''/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'content0-block-detail',
              children: '单相壁挂式远传电表',
            },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>DDS5168B</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'ddsu4p',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={electricityDDSU4p} alt=''/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'content0-block-detail',
              children: '单相电子式电能表（导轨4P）',
            },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>DDSU5168</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'dtsd5168',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={electricityDTSD} alt=''/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'content0-block-detail',
              children: '三相四线电子式多功能电能表',
            },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>DTSD5168</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
    ],
  },
};
export const Feature02DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'r200',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={hotJOYR200} alt=''/>
                        },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            { name: 'content', 
            className: 'content0-block-detail',
            children: 'JOYR200超声波热量表' },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>JOYR200</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'h100',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={hotJOYH110} alt=''/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'content0-block-detail',
              children: 'JOYH100电子式热分配表',
            },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>JOYH100</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
    ],
  },
};
export const Feature03DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'ct213',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={gatewayCT213} alt=''/>
                        },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            { name: 'content', 
            className: 'content0-block-detail',
            children: 'JOYC-CT213水电集抄网关' },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>JOYC-CT213</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'cv151',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={gatewayCV151} alt=''/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'content0-block-detail',
              children: 'JOYC-CV151 RS485转换模块',
            },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>JOYC-CV151</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          name:'gw200',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:<img src={gatewayGW220} alt=''/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '',
            },
            {
              name: 'content',
              className: 'content0-block-detail',
              children: 'JOYC-GW220网关',
            },
            {
              name:'skip',
              className:'content0-block-skip',
              children:<>
              <div>
              <div>JOYC-GW220</div>
              <div className='right-icon'><img src={rightIcon} alt=''/></div>
              </div>
              </>
            }
          ],
        },
      },
    ],
  },
};
export const Pricing11DataSource = {
  wrapper: { className: 'home-page-wrapper pricing1-wrapper' },
  page: { className: 'home-page pricing1' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span style={{display:'block',marginTop:'-2rem',marginBottom:'1rem'}}>
            <p>企业数字化能源管理</p>
            
          </span>
        ),
        className: 'pricing1-title-h1',
      },
      {
        name: 'title~lemg13v11wc',
        className: 'lemg14jwgu-editor_css',
        children: (
          <span>
            <p>DIGITAL ENERGY MANAGEMENT FOR ENTERPRISES</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box  lemg6419i28-editor_css' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: '' },
          money: { className: 'pricing1-money', children: '' },
          content: {
            className: 'pricing1-content',
            children: (
              <img src={project1} alt='' width={'100%'}/>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button lemg6o6e2t9-editor_css',
                href: '#',
                children: '免费试用',
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box  lemg6cywfyl-editor_css' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: '' },
          money: { className: 'pricing1-money', children: '' },
          content: {
            className: 'pricing1-content',
            children: (
              <img src={project2} alt='' width={'100%'}/>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button lemg6h95uq-editor_css',
                href: '#',
                children: '立即购买',
              },
            },
          },
        },
      },
      {
        name: 'block~lemfy52sri',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box  lemg6egoak-editor_css' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: '' },
          money: { className: 'pricing1-money', children: '' },
          content: {
            className: 'pricing1-content',
            children: (
              <img src={project3} alt='' width={'100%'}/>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button lemg6mhdhx-editor_css',
                href: '#',
                children: '立即购买',
              },
            },
          },
        },
      },
    ],
  },
};
export const Pricing10DataSource = {
  wrapper: { className: 'home-page-wrapper pricing1-wrapper' },
  page: { className: 'home-page pricing1 ledx7lwr4lt-editor_css' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span style={{display:'block',marginBottom:'1.5rem'}}>
      
                    <p>我们的优势</p>
                    
         
          </span>
        ),
        className: 'pricing1-title-h1 ledxakw154-editor_css',
      },
      {
        name: 'title~ledxe1kdg4m',
        className: 'ledxe8kb16q-editor_css',
        children: (
          <span style={{display:'block',marginTop:'1rem'}}>
           
                  <p>OUR&nbsp; ADVANTAGE</p>
             
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box  ledxa7i8dz-editor_css' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: '' },
          money: { className: 'pricing1-money', children: '' },
          content: {
            className: 'pricing1-content',
            children: (
            <img src={Pricing1} alt='' width={'100%'}/>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button ledx9kze84o-editor_css',
                href: '#',
                children: '免费试用',
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box  ledx9yvky67-editor_css' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: '' },
          money: { className: 'pricing1-money', children: '' },
          content: {
            className: 'pricing1-content',
            children: (
              <img src={Pricing2} alt='' width={'100%'}/>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button ledx9fsdvbo-editor_css',
                href: '#',
                children: '立即购买',
              },
            },
          },
        },
      },
      {
        name: 'block~ledvqzf1e1',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box  ledxa5reobt-editor_css' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: '' },
          money: { className: 'pricing1-money', children: '' },
          content: {
            className: 'pricing1-content',
            children: (
              <img src={Pricing3} alt='' width={'100%'}/>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button ledx9jrm6b-editor_css',
                href: '#',
                children: '立即购买',
              },
            },
          },
        },
      },
    ],
  },
};
export const Pricing20DataSource = {
  wrapper: { className: 'home-page-wrapper Pricing2-wrapper' },
  page: { className: 'home-page Pricing2 ledx7lwr4lt-editor_css' },
  OverPack: { playScale: 0.3, className: 'Pricing2-content-wrapper' },
  titleWrapper: {
    className: 'Pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>我们的优势</p>
                    <p>
                      <br />
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'Pricing2-title-h1 ledxakw154-editor_css',
      },
      {
        name: 'title~ledxe1kdg4m',
        className: 'ledxe8kb16q-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>OUR&nbsp; ADVANTAGE</p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'Pricing2-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'Pricing2-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'Pricing2-block-box  ledxa7i8dz-editor_css' },
          topWrapper: { className: 'Pricing2-top-wrapper' },
          name: { className: 'Pricing2-name', children: '' },
          money: { className: 'Pricing2-money', children: '' },
          content: {
            className: 'Pricing2-content',
            children: (
            <></>
            ),
          },
          line: { className: 'Pricing2-line' },
          buttonWrapper: {
            className: 'Pricing2-button-wrapper',
            children: {
              a: {
                className: 'Pricing2-button ledx9kze84o-editor_css',
                href: '#',
                children: '免费试用',
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'Pricing2-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'Pricing2-block-box  ledx9yvky67-editor_css' },
          topWrapper: { className: 'Pricing2-top-wrapper' },
          name: { className: 'Pricing2-name', children: '' },
          money: { className: 'Pricing2-money', children: '' },
          content: {
            className: 'Pricing2-content',
            children: (
              <></>
            ),
          },
          line: { className: 'Pricing2-line' },
          buttonWrapper: {
            className: 'Pricing2-button-wrapper',
            children: {
              a: {
                className: 'Pricing2-button ledx9fsdvbo-editor_css',
                href: '#',
                children: '立即购买',
              },
            },
          },
        },
      },
      {
        name: 'block~ledvqzf1e1',
        className: 'Pricing2-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'Pricing2-block-box  ledxa5reobt-editor_css' },
          topWrapper: { className: 'Pricing2-top-wrapper' },
          name: { className: 'Pricing2-name', children: '' },
          money: { className: 'Pricing2-money', children: '' },
          content: {
            className: 'Pricing2-content',
            children: (
              <></>
            ),
          },
          line: { className: 'Pricing2-line' },
          buttonWrapper: {
            className: 'Pricing2-button-wrapper',
            children: {
              a: {
                className: 'Pricing2-button ledx9jrm6b-editor_css',
                href: '#',
                children: '立即购买',
              },
            },
          },
        },
      },
    ],
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1 lef1h1clqw7-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>他们选择了源一</p>
          </span>
        ),
        className: 'lef1j5kres-editor_css',
      },
      {
        name: 'title~lef1j90udr6',
        className: 'lef1jam14s-editor_css',
        children: (
          <span>
            <p>OUR PARTNERS</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block lef19irw1bh-editor_css',
        md: 12,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image lef1gpupl1-editor_css',
              children:
                partner1,
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block lef19fnzs0p-editor_css',
        md: 12,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image lef1bs195n-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*njqxS5Ky7CQAAAAAAAAAAABjARQnAQ',
            },
          ],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 12,
        className: 'block',
        title: {
          className: 'logo',
          children:
            (
              <><img src={logo} alt=''/></>
            ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div className='test'>
                  <span className='title'>联系我们</span>
                  <ul style={{listStyle:'none',marginLeft:'12px'}}>
                    <li className='left'>商务合作：400-826-6829</li>
                    <li>工厂地址：嘉兴市秀洲区油车港镇正阳西路88号</li>
                    <li>办公地址：杭州市滨江区长河街道中赢星海创投中心2幢1804室</li>
                  </ul>
                  <div className='qrcode' >
                    <div className='first' style={{width:'150px'}}>
                  <img src={wechat} alt='' width={'100%'}/>
                  <div style={{textAlign:'center'}}>关注公众号</div>
                  </div>
                  <div style={{width:'150px'}}>
                  <img src={wecom} alt='' width={'100%'}/>
                  <div style={{textAlign:'center'}}>企业微信</div>
                  </div>
                    </div>
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block cancel',
        title: { children: '产品' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '#',
              to: {pathname:'/software',state:{name:'产品',type:'software'}},
              children: (
                <span>
                  <p>软件产品</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '#',
              to: {pathname:'/hardware',state:{name:'产品',type:'hardware'}},
              children: (
                <span>
                  <p>硬件产品</p>
                </span>
              ),
            },
            
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 4,
        className: 'block cancel',
        title: {
          children: (
            <span>
              <p>解决方案</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              to: {pathname:'/solution',state:{name:'长租公寓',type:'flats'}},
              name: 'link0',
              children: (
                <span>
                  <p>长租公寓</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              to: {pathname:'/solution',state:{name:'公租房',type:'publicHousing'}},
              children: (
                <span>
                  <p>公租房</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              to: {pathname:'/solution',state:{name:'智慧商场',type:'market'}},
              children: (
                <span>
                  <p>智慧商场</p>
                </span>
              ),
            },
            {
              href: '#',
              to:{pathname:'/solution',state:{name:'写字楼',type:'officeBuilding'}},
              name: 'link1',
              children: (
                <span>
                  <p>写字楼</p>
                </span>
              ),
            },
            {
              href: '#',
              to:{pathname:'/solution',state:{name:'园区能耗',type:'park'}},
              name: 'link1',
              children: (
                <span>
                  <p>园区能耗</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 4,
        className: 'block cancel',
        title: {
          children: (
            <span>
              <span>
                <p>关于我们</p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              to:{pathname:'/about'},
              children: (
                <span>
                  <p>公司简介</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              to:{pathname:'/about'},
              children: (
                <span>
                  <p>联系我们</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <>
       <div> © 2023 杭州源一物联科技有限公司 All Rights Reserved</div>
       <div style={{marginLeft:'8px'}}><a href="https://beian.miit.gov.cn"> 浙ICP备2023003109号</a></div>
      </>
    ),
  },
};
export const Contentsoft10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: soft1,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title',
   children: (
     <div>
       <div style={{fontSize:'24px',fontWeight:'normal'}}>Metering Expert</div>
       <div>计量行业专家</div>
     </div>
   ) 
  },
  content: {
    className: 'content1-content',
    children:
      '合一智能抄表致力于为客户提供专业的表计数据服务，是源一物联深根能源计量领域多年打造的表计IOT平台。',
  },
  button:{
    className: 'content1-button',
    children:(
      <></>
    )
  }
};
export const Contentsoft20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: soft2,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children:(
    <div>
    <div style={{fontSize:'24px',fontWeight:'normal'}}>Better Living</div>
    <div>让租住更美好</div>
  </div>
  ) },
  content: {
    className: 'content2-content',
    children:
      'AMS租赁管理系统是源一物联专为公寓、园区、商业综合体等行业深度打造的能源计量与收费管理系统，提供租赁管理全链路解决方案。',
  },
  button:{
    className: 'content2-button',
    children:(
      <></>
    )
  }
};
export const Contentsoft30DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: soft3,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: (
    <div>
    <div style={{fontSize:'24px',fontWeight:'normal'}}>Low Carbon</div>
    <div>瞄准节能目标</div>
  </div>
  ) },
  content: {
    className: 'content1-content',
    children:
      '源一能耗平台专注于企业用电供电配电的信息管理，提升能源管理的数字化水平，有助于客户降低运营成本，实现节能减排。',
  },
  button:{
    className: 'content1-button',
    children:(
      <></>
    )
  }
};
